.Poemlistview{
    background-color: rgb(241, 241, 241);
}
.literaryhub-bgimg {

    background: #000;
    background-image: url("../../Assets/pageheader/2150716538.jpg");
    background-position: initial;
    background-size: cover;
}

.literaryhub-blackdrop {
    background-color: #000000ab;
    height: 380px;

}


.literaryhub-title {
    font-size: 3.5rem;
    font-family: "Merriweather", serif;
    font-weight: 400;
    color: white;
    padding-top: 7rem;
    text-align: center;
}


.literaryhub-content {

    margin-left: 0rem;
    margin-right: 0rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding-bottom: 2rem;
    background-color: #D4A373;
}
.literaryhub-content2{
    margin-left: 6rem;
    margin-right: 6rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    
}
.literaryhub-content h2 {
    font-size: 2.5rem;
    font-family: "Merriweather", serif;
    font-weight: 400;
    color: white;
    padding-top: 3rem;
    padding-bottom: 2rem;
    text-align: center;
}
.literaryhub-content2 h2 {
    font-size: 2.5rem;
    font-family: "Merriweather", serif;
    font-weight: 400;
    color: black;
    padding-top: 1rem;
    padding-bottom: 2rem;

    text-align: center;
}
.literaryhub-article-container1 {
    border-radius: 1rem;
    border: solid 1px #D4A373;
    margin-left: 0rem;
    margin-right: 0rem;
    padding-left:1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.Poemlistview a{
    text-decoration: none;
    color: #000;
}
.Articleslistview a{
    text-decoration: none;
    color: #000;
}
.Shortstorieslistview a{
    text-decoration: none;
    color: #000;
}

.literaryhub-content-card {
    background-color: white;
    margin-left: .5rem;
    margin-right: .5rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-decoration: none;
}
.literaryhub-content-card:hover{
    box-shadow: 10px 11px 29px -5px rgba(0,0,0,0.27);
    -webkit-box-shadow: 10px 11px 29px -5px rgba(0,0,0,0.27);
    -moz-box-shadow: 10px 11px 29px -5px rgba(0,0,0,0.27);
}
.literaryhub-cover-img {
    height: -webkit-fill-available;
    width: 13rem;
    padding: .5rem;
    text-align: center;
    border-radius: 1rem;
    object-fit: cover;
    
}
.literaryhub-cover-img2 {
    height: 13.5rem;
    width: 18rem;
    padding: .5rem;
    text-align: center;
    border-radius: 1rem;
    object-fit: cover;
    
}


.literary-cardcontent-col {
    align-content: center;
    padding: 1rem;
}

.literaryhub-cardcontent-title {
    font-size: 1.7rem;
    text-align: left;
    font-family: "Merriweather", serif;
    margin-bottom: .2;
    margin-left: 1rem;
}

.literaryhub-cardcontent-description {
    font-size: 1rem;
    text-align: justify;
    font-family: "Teachers", sans-serif;
    margin-bottom: .3;
    margin-top: .3;
    margin-left: 1rem;
    margin-right: 1.5rem;
    
}

.literaryhub-cardcontent-authorname {
    font-size: 1.3rem;
    text-align: left;
    font-family: "Merriweather", serif;
    font-weight: 400;
    margin-bottom: .2;
    margin-left: 1rem;
    float: left;
}

.literaryhub-cardcontent-posteddate {
    font-size: .9rem;
    text-align: left;
    font-family: "Teachers", sans-serif;
    font-weight: 500;
    margin-bottom: .2;
    margin-left: 1rem;
    float: right;
    margin-right: 1.5rem

}


.literaryhub-hookredirect {

    margin-bottom: 6em;
    margin-bottom: 0rem !important;
    background-color: #000;
    background-image: url("../../Assets/imagepplbehindredirect.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
    background-position: center;
    /* border-radius: 2rem; */
    font-family: 'Alata', sans-serif;

}


.literaryhub-hookredirect-content {
    background-color: #000000d0;
    padding: 6em;
    align-items: center;
    width: 100%;
    --bs-gutter-x: 0rem !important;
}

.literaryhub-hookredirect-content p {
    font-size: 3.5rem;
    font-family: "Merriweather", serif;
    font-weight: 400;
    color: white;
    text-align: center;
}

.literaryhub-hookredirect-button {
    text-align: center;
}

.literaryhub-hookredirect-button button {
    background-color: #6A1B1B;
    color: white;
    border: none;
    border-radius: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 15rem;
    text-align: center;

}


@media only screen and (max-width: 992px) {
    .literaryhub-bgimg {
        background-position: top;
        background-size: cover;
    }

    .literaryhub-blackdrop {
        background-color: #000000ab;
        height: 175px;
    }

    .literaryhub-title {
        font-size: 2rem;
        padding-top: 4.5rem;
    }
.literaryhub-article-container1-row{
    display: contents;
    /* margin-left: 0 !important; */
    /* margin-right: 0 !important; */
}
    .literaryhub-content {

        margin-left: 0rem;
        margin-right: 0rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: 0rem;
        margin-bottom: 2rem;
        border-radius: 0rem;
    }
    .literaryhub-content2 {

        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
        border-radius: .5rem;
    }
    
    .literaryhub-content h2 {
        font-size: 2rem;
        padding-top: 1rem;
        padding-bottom: .1rem;
      

    }
    .literaryhub-content2 h2 {
        font-size: 1.7rem;
        padding-top: 0rem;
        padding-bottom: 1rem;
    }
    .literaryhub-cover-img {
        height: 10rem;
        width: 100%;
        padding: 0rem !important;
        text-align: center;
        border-radius: .5rem;
        object-fit: cover;
        
    }
    .literaryhub-cover-img2 {
        height: 10rem;
        width: 100%;
        padding: 0rem !important;
        text-align: center;
        border-radius: 0rem;
        object-fit: cover;
        
    }
    .literaryhub-content-card {
        justify-self: center;
        background-color: white;
        margin-left: .5rem;
        margin-right: .5rem;
        margin-bottom: 1rem;
        border-radius: .5rem;
        padding-top: .8rem;
        padding-bottom: .8rem;
        text-decoration: none;
    }
    
    .literaryhub-cardcontent-title {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 0rem;
    margin-top: .5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.literaryhub-cardcontent-description {
    font-size: .8rem;
    padding-top: .5rem;
    margin-bottom: .3rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.literaryhub-cardcontent-authorname {
    font-size: 1.2rem;
    margin-bottom: .2rem;
    margin-left: 1rem;
    float: left;
}

.literaryhub-cardcontent-posteddate {
    font-size: .6rem;
    font-weight: 400;
    margin-bottom: 0rem !important;
    margin-top: .5rem;
    margin-left: 1rem;
    float: right;
    margin-right: 1rem

}
.literaryhub-hookredirect-content {
    background-color: #000000d0;
    padding: 2rem;
    align-items: center;
    width: 100%;
    --bs-gutter-x: 0rem !important;
}

.literaryhub-hookredirect-content p {
    font-size: 2.5rem;
}

.literaryhub-hookredirect-button button {
    border-radius: .5rem;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-size: 1rem;
}
}