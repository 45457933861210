.Getintouch {
    margin-left: 6rem;
    margin-right: 6rem;
    /* padding-top: 6rem; */
    padding-bottom: 5rem;

}

.Getintouch h2 {
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 2.7rem;
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.Getintouch-left-content {

    margin-top: 5em;
    justify-content: flex-end;
    font-family: 'Alata', sans-serif;
    text-align: left;

}

.socialmedia-img {
    height: 9rem;
    width: 13rem;
}

.Contactusform {
    border: solid 0.1px rgba(102, 51, 153, 0.404);
    border-radius: 2rem;
    padding: 4rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 2rem;
}

.form-label {
    font-size: 1.5rem
}

.contactus {

    color: white;
    text-decoration: none;
    background-color: #6A1B1B;
    border-color: #6A1B1B;
    text-align: center;
    font-size: 1rem;
    border-radius: .5rem;
    width: 100%;
    height: 3rem;
}
.contactus:hover {
    background-color: #a32525;
    border-color: #a32525;
}

.contactus:active {
    background-color: #a32525;
    border-color: #a32525;
}

.contactform-title {
    font-weight: 700;
    margin-bottom: 2rem;
    font-size: 4em;

}

.Faqcontent {
    margin-top: 4rem;
}

.faq-description {
    font-size: 1rem;
    font-family: "Teachers", sans-serif;
    font-weight: 400;


}
.Getintouch a{
    text-decoration: none !important;
  }

@media only screen and (max-width: 992px) {
    .Getintouch {
        margin-left: .1rem;
        margin-right: .1rem;
        padding-bottom: .5rem;
    
    }
    .Getintouch-row {
        margin-left: .1rem;
        margin-right: .1rem;
        padding-bottom: 3rem;
    
    }
    .Getintouch h2 {
        font-size: 2rem;
        text-align: center;
        padding-top: 0rem;
        padding-bottom: .5rem;
    }
    .Getintouch-left-content {
        margin-top: 2rem;
    }
    .socialmedia-img {
        height: 5rem;
        width: 6.9rem;
    }
    
    .Contactusform {
        border-radius: .5rem;
        margin-top: 1rem;
        margin-left: 0rem;
        margin-right: 0rem;
        padding: 1.5rem;

    }
    
    .form-label {
        font-size: .9rem
    }

    .contactus {
        width: 100%;
        height: 2.5rem;
        font-size: .9em;
    }

    .contactform-title {
        text-align: center;
        font-size: 2em;
    }

    .Faqcontent {
        margin-top: 2rem;
    }

    .faq-description {
        font-size: .8rem;
    }

    .accordion-button {
        font-size: .8rem !important;
    }
}