
.footer-title {
    font-weight: 300;
    font-size: 3em;
    margin-bottom: 0;
}

.footer-subtitle {
    font-family: 'Alata', sans-serif;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 3em;
}

.footer-description {
    font-style: italic;
    padding-top: 1rem;
    padding-bottom: 2.5rem;
    font-size: 1em;
}

.footer {
    background-color: #3C3C3B;
}

.footer-col {
    display: flex;
    margin-left: 6em;
    margin-right: 6em;
    padding-top: 3.5em;
    padding-bottom: 3.5em;
}

.footer-col img {
    height: 5rem;
    width: 5rem;
}

.footer-col a {
    text-decoration: none;
    font-size: 1.2rem;
    color: white;
    opacity: 80%;
    font-family: "Teachers", sans-serif;
}

.footer-col p {
    color: white;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-right: 6rem;
    font-family: "Teachers", sans-serif;

}

.footer-col h3 {
    color: white;
    font-family: Georgia, serif;
    margin-bottom: 1rem !important;
}

@media only screen and (max-width: 992px) {
    .footer {
        margin-top: 0rem;
    }

    .footer-col {
        margin-left: 1em;
        margin-right: 1em;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .footer-col h3 {
        font-size: 1rem;
    }

    .footer-col a {
        font-size: .6rem;
    }

    .footer-col p {
        padding-right: 0;
        font-size: .6rem;
        margin-bottom: 0;
    }

    .footer-col-right-content h3 {
        padding-top: 1rem;
    }

}