.SinglePoemView{
    background: #F5F1E0;
}
.SingleArticleView{
    background: #F5F1E0;

}
.Poemcontent-margin{
    margin-left: 24rem;
    margin-right: 24rem;

}
.poemcontent-title{
    font-size: 3.5rem;
    font-family: "Merriweather", serif;
    font-weight: 400;
    padding-top: 3rem;
    padding-bottom: 2rem;
    text-align: center;
}
.poemcontent-coverpic{
   
    height: 25rem;
    width: 100%;
    margin-bottom: 3rem;
    object-fit: cover;
    border-radius: .5rem;
    border: solid 2px #D4A373;
}
.poemcontent-content{

    font-size: 1.4rem;
    text-align: left;
    justify-self: center;
    font-family: "Teachers", sans-serif;
    margin-bottom: .3;
    margin-top: .3;
   
}
.poemcontent-sidecard{
    background: white;
    /* justify-self: center; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* margin-left: 2rem; */
    /* margin-right: 2rem; */
    padding-left: 2rem;
    padding-right: 2rem;
    border: solid 1px #D4A373;
    border-radius: .5rem;
    margin-bottom: 2rem;
}
.poemcontent-sidecard h4{
    font-size: 1.7rem;
    font-family: "Merriweather", serif;
    font-weight: 400;
    padding-top: .5rem;
    padding-bottom: 1rem;
}
.poemcontent-sidecard p{
    margin-bottom: .5rem;
    font-size: 1rem;
    text-align: justify;
    font-family: "Teachers", sans-serif;
}
.poemcontent-sidecard-name{
    font-size: 1.5rem;
    font-family: "Teachers", sans-serif;
    font-weight: 600;
    text-align: left;
    
}
.Demo__container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 30px);
    max-width: 400px;
    margin: 0 auto;
    padding-top: 1rem;
  }
  
  .Demo__some-network {
    text-align: center;
  }
  .Demo__some-network__share-count {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
    width: 0;
    font-size: 14px;
  }
  
  .Demo__some-network__share-button {
    cursor: pointer;
  }
  
  .Demo__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
  }
  
@media only screen and (max-width: 992px) {
    .Poemcontent-margin{
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .poemcontent-title{
        font-size: 1.7rem;
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
    .poemcontent-coverpic{
       
        height: 10rem;
        width: 100%;
        margin-bottom: 2rem;
    }

    .poemcontent-content{

        font-size: 1rem;
        text-align: left;
        justify-self: center;
        font-family: "Teachers", sans-serif;
        margin-bottom: .3rem;
        margin-top: 0rem;
       
    }
    .poemcontent-sidecard{
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: .5rem;
        margin-bottom: 1rem;
    }
    .poemcontent-sidecard h4{
        font-size: 1.4rem;
        padding-top: .5rem;
        padding-bottom: 0rem;
    }

    .poemcontent-sidecard p{
        margin-bottom: .5rem;
        font-size: .9rem;
    }
    .poemcontent-sidecard-name{
        font-size: 1.4rem;
        
    }
}