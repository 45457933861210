.Nav-link{
    padding-left: 2em;
}
.color{
    background-color: white !important;
    /* background-color: transparent !important; */


    /* box-shadow: 0px 9px 65px -2px rgba(125,125,125,0.8);
    -webkit-box-shadow: -1px 9px 65px -2px rgba(125,125,125,0.8);
    -moz-box-shadow: -1px 9px 65px -2px rgba(125,125,125,0.8); */
}

.container{
    margin-left: 10em !important;
    /* margin-right: 0em !important; */

  
}
.Brandlogo{
    margin-left: 12rem;
}
.Brandlogo img{
    height: 3rem;
    width: 3rem;
    /* border-radius: rem; */
}
.navbar>.container{
    display: contents;
}
.Navbarcomponents{
    overflow: hidden;
    margin-right: 10rem;
}
.Navbarcomponents a{
    padding-right: 2em !important;
    padding-left: 2em !important;
    font-family: "Teachers", sans-serif;

    font-size: 1.1em;
    font-weight: 600;
    padding-top: 1em;
    padding-bottom: 1em;
    color: #212529;
}
@media only screen and (max-width: 992px) {
    .Brandlogo img{
       /* padding-left: .5rem; */
        overflow: hidden;
        
    }
    .container{
        margin: 0 !important;
    }
    .Brandlogo{
        margin-left: 1rem;
    }
    .toggle{
        margin-right: 1rem;
    }
}


header{
    padding: 10px 0px;
}

.header-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-wrapper a{
    color: #313131;
}

.header-wrapper nav{
    display: flex;
    gap: 25px;
}
@media only screen and (max-width: 768px) {
   
    .header-wrapper{
        flex-direction: column;
        gap: 30px;
    }


}